@font-face {
  font-family: AktivGroteskCorp;
  src: url(../fonts/AktivGroteskCorp-Light.woff);
}

.final-screen {
  color: #fff;
  text-align: center;
  &__title {
    font-weight: bold;
    font-size: 150px;
    line-height: 100px;
    margin-bottom: 48px;
  }

  &__logo {
    margin-bottom: 4px;
  }

  &__small-text {
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
    margin-bottom: 48px;
  }

  &__text {
    font-family: AktivGroteskCorp, Arial, sans-serif;
    font-size: 34px;
    line-height: 40px;
    text-transform: uppercase;
    font-stretch: 95%;
  }

  @media (max-width: 813px) {
    &__title {
      font-size: 100px;
    }

    &__logo img {
      max-width: 90vw;
    }
  }
}