@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@700&display=swap');

.aside {
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  width: 96px;
  background-color: #F1F1F1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: "Roboto", sans-serif;
  box-shadow: -2px 0px 8px rgba(0, 0, 0, 0.15);
  z-index: 10;

  a {
    display: block;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.54);
    padding: 12px 0;
    transition: .1s linear;
    text-align: center;

    &.active, &:hover {
      color: #232323;
      transform: scale(1.5);
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  @media (max-width: 813px) {
    left: 0;
    right: 0;
    top: auto;
    bottom: 0;
    flex-direction: row;
    width: 100%;
    max-width: 100vw;
    overflow-x: auto;
    justify-content: flex-start;
    align-items: center;
    overflow-y: visible;
    height: 70px;

    // hide scrollbar
    &::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none;
    scrollbar-width: none;

    a {
      padding: 12px;
      height: 48px;
      min-width: 70px;
      position: relative;

      img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
}