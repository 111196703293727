.screens {
  height: calc(100vh);
  overflow: hidden;
  position: relative;

  &__inner {
    width: 100%;
    transition: .5s ease-out;
  }

  @media (max-width: 813px) {
    height: calc(100vh - 70px);
    height: calc(var(--vh, 1vh) * 100 - 70px);
  }
}