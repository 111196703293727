.mfp-container {
  //display: flex;
  //align-items: center;

  .mfp-content {
    .mfp-figure {
      &::after {
        display: none;
      }

      .mfp-close {
        font-size: 0;
        width: 32px;
        height: 32px;
        background: url(/src/img/icons/close-gallery-button.svg) 0 0 no-repeat;
        background-size: 32px 32px;
        right: -14px;
        top: 24px;
        opacity: 1;
      }

      .mfp-bottom-bar {
        //margin-top: 8px;

        .mfp-title {
          background: #990032;
          padding: 6px;
          font-weight: 300;
          font-size: 16px;
          line-height: 20px;
          text-align: center;
          color: #FFFFFF;

          &:empty {
            display: none;
          }
        }

        .mfp-counter {
          display: none;
        }
      }
    }
  }
}
