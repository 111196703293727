.active {
  > .window {
    opacity: 1;
    transform: translateY(0);
  }
}

.window {
  max-width: 480px;
  max-height: 565px;
  background-color: rgb(0, 0, 0, .6);
  border-radius: 2px;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 48px 30px 43px;
  transition: .2s linear;
  user-select: none;
  position: relative;
  opacity: 0;
  transform: translateY(-50px);

  * {
    transition: .2s linear;
  }

  &--small {
      height: auto;
  }

  &__first-slide-text {
    font-size: 32px;
    font-weight: bold;
    text-align: center;
    line-height: 1.3;
    margin-bottom: 0;
  }

  &__inner {
    max-height: 100%;
    overflow-y: auto;
    text-align: center;
  }

  &__close {
    position: absolute;
    display: none;
    top: 50px;
    right: -14px;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    background-color: #232323;
    background-image: url("../img/icons/close.svg");
    background-size: 14px;
    background-position: center;
    background-repeat: no-repeat;
  }



  &.active &__close {
    display: block;
  }

  &:hover, &.active {
    box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.25);
    background-color: rgb(255, 255, 255);
    color: #232323;
  }

  &.active {
    height: 872px;
    max-height: 100vh;
  }

  &__year {
    font-weight: bold;
    font-size: 150px;
    line-height: 113px;
    letter-spacing: 0.01em;
    margin-bottom: 24px;
  }

  &__description {
    color: #FAFAFA;
    line-height: 20px;
    text-align: center;

    &--first-slide-text {
      font-size: 20px;
      line-height: 30px;

      + div {
        margin-top: 30px;
      }
    }
  }


  &:hover &__description, &.active &__description {
    color: #232323;
  }

  &.active &__inner > &__description {
    display: none;
  }

  &__logo {
    margin-bottom: 4px;
  }

  &__logo-description {
    margin-bottom: 24px;
    text-align: center;
    color: #929292;
  }

  &:hover &__logo-description, &.active &__logo-description {
    color: #888888;
  }

  &__footer {
    padding-top: 16px;
    display: flex;
    justify-content: center;
  }

  &__next {
    border-radius: 4px;
    padding: 10px;
    color: #333;
    margin-top: 32px;
    font-size: 0;
    width: 36px;
    height: 36px;
    min-height: 36px;
    background: #fff;
    position: relative;
    border: none;
    outline: none;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &::before {
      content: "";
      display: block;
      width: 11px;
      height: 11px;
      background: url(/src/img/icons/arrow-down.png) 0 0 no-repeat;
    }
  }

  &:hover &__next {
    background-color: #232323;
    color: #fff;

    &::before {
      background-position: 0 -11px;
    }
  }

  &__read-more {
    width: 36px;
    height: 36px;
    background-image: url("../img/icons/read-more-black.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-color: #fff;
    border-radius: 4px;
    transition: none !important;
  }

  &:hover &__read-more {
    background-color: #232323;
    background-image: url("../img/icons/read-more.svg");
  }

  &.active &__read-more {
    display: none;
  }

  &__full-description {
    height: 0;
    overflow: hidden;
  }

  &.active &__full-description {
    height: auto;
  }

  &__title {
    display: flex;
    align-items: center;
    padding: 16px 0;

    span {
      padding: 0 16px;
      font-weight: bold;
      font-size: 18px;
      line-height: 20px;
      color: #F05E21;
    }

    &::before, &::after {
      content: "";
      display: block;
      background: #F05E21;
      height: 2px;
      flex: 1
    }
  }

  &__separator {
    height: 4px;
    border-top: 1px solid #F05E21;
    border-bottom: 1px solid #F05E21;
    margin: 22px 0;
  }
  @media (max-width: 813px) {
    padding: 30px 0;
    margin: auto auto;

    &.active {
      //height: calc(100vh - 38px);
      //max-height: calc(100vh - 38px);
      height: 100%;
      max-height: 100%;
    }
    &__close {
      right: 10px;
    }
    &__year {
      font-size: 100px;
      line-height: 90px;
    }

    &__first-slide-text {
      //font-size: 32px;
      font-size: 20px;
    }
    &__description {
      padding: 0 5px;

      &--first-slide-text {
        font-size: 15px;
        line-height: 22px;

        + div {
          margin-top: 20px;
        }
      }
    }
  }
}

.carousel {
  display: flex;

  &__inner {
    flex: 1
  }

  img {
    margin-right: 10px;
  }
  button {
    border: none;
    background-color: transparent;
    width: 32px;
    background-repeat: no-repeat;
  }

  &__prev {
    background-image: url("../img/icons/slider-arrow-left.svg");
    background-position: left center;
  }

  &__next {
    background-image: url("../img/icons/slider-arrow-right.svg");
    background-position: right center;
  }

  * {
    transition: none;
  }

}