.screen {
  min-height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: .1s linear;
  background-position: 50% 50%;

  &.active {
    opacity: 1;
    filter: none;

  }

  &--0 {
    background-image: url("../img/bg6.jpg");
  }

  &--1 {
    background-image: url("../img/bg1.jpg");
  }

  &--2 {
    background-image: url("../img/bg2.jpg");
  }

  &--3 {
    background-image: url("../img/bg3.jpg");
  }

  &--4 {
    background-image: url("../img/bg4.jpg");
  }

  &--5 {
    background-image: url("../img/bg5.jpg");
  }

  &--6 {
    background-image: url("../img/bg6.jpg");
  }

  &--7 {
    background-image: url("../img/bg7.jpg");
  }

  &--8 {
    background-color: #9B0030;
    background-image: url("../img/bg8.svg");
  }
  &--9 {
    background-position: bottom;
    background-image: url("../img/bg9.jpg");
  }

  @media (max-width: 813px) {
    min-height: calc(100vh - 70px);
    min-height: calc(var(--vh, 1vh) * 100 - 70px);
    height: calc(100vh - 70px);
    height: calc(var(--vh, 1vh) * 100 - 70px);
  }
}

@import "window";