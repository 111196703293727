@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  line-height: 1;

  &:focus {
    outline: none;
  }
}

a {
  text-decoration: none;
}

ul {
  list-style: none;
}

body {
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  background: #000;
  overflow-x: hidden;

  overscroll-behavior: contain;
}

@import "components/screen";
@import "components/aside";
@import "components/screens";
@import "components/final-screen";
@import "components/gallery";